var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ReportLayout',{attrs:{"radioName":_vm.radioName,"radioLogo":_vm.radioLogo,"reportName":"Extrato musical","sublabel":_vm.title + ' - ' + _vm.artist,"periodStart":_vm.periodStart,"periodEnd":_vm.periodEnd,"numberOfPages":_vm.data.collectionPerHour.length > 9 ? 2 : 1},scopedSlots:_vm._u([{key:"page-1",fn:function(){return [_c('div',{staticClass:"md-layout-item md-size-100",staticStyle:{"margin":"0 auto"}},[_c('ReportCard',{attrs:{"title":'Estatísticas gerais',"labels":['Total de likes', 'Total de dislikes', 'Total de votos', 'Total de reproduções', 'Total de pedidos'],"keys":['totalLikes', 'totalDislikes', 'totalVotes', 'totalPlayed', 'totalRequested'],"cardData":[{
						totalLikes: _vm.$options.filters.formatNumber(_vm.data.totalLikes),
						totalDislikes: _vm.$options.filters.formatNumber(_vm.data.totalDislikes),
						totalVotes: _vm.$options.filters.formatNumber(_vm.data.totalVotes),
						totalPlayed: _vm.$options.filters.formatNumber(_vm.data.totalPlayed),
						totalRequested: _vm.$options.filters.formatNumber(_vm.data.totalRequested),
					}],"dataDisplay":'big-columns'}})],1),_c('div',{staticClass:"md-layout-item md-size-100",staticStyle:{"margin":"0 auto"}},[_c('ReportCard',{attrs:{"title":'Extrato por hora',"labels":['Hora', 'Likes', 'Dislikes', 'Votos', 'Reproduções', 'Pedidos'],"keys":['hour', 'total_likes', 'total_dislikes', 'total_votes', 'total_played', 'total_requests'],"cardData":_vm.data.collectionPerHour.slice(0, 7),"dataDisplay":'long-table'}})],1)]},proxy:true}])},[(_vm.data.collectionPerHour.length > 9)?_c('ReportCard',{staticClass:"md-layout-item md-size-100",staticStyle:{"margin":"0 auto"},attrs:{"slot":'page-2',"title":'Extrato por hora',"labels":['Hora', 'Likes', 'Dislikes', 'Votos', 'Reproduções', 'Pedidos'],"keys":['hour', 'total_likes', 'total_dislikes', 'total_votes', 'total_played', 'total_requests'],"cardData":_vm.data.collectionPerHour.slice(7, 24),"dataDisplay":'long-table'},slot:'page-2'}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }